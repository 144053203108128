import { BaseEntity } from './shared/base-entity';
import { MediaItem } from './media-item.entity';
import { LeadContactDetails } from './shared/lead';
import { PatientMessage } from './patient-message.entity';
import { UserInfo } from './user-info.entity';
import { PurchaseOption } from './commerce/purchase-option';

export enum Resolution {
  Approved = 'approved',
  Rejected = 'rejected',
}

export enum DiseaseCategory {
  HairLoss = 'hairLoss',
  Skincare = 'skincare',
  ErectileDysfunction = 'erectileDysfunction',
  WeightLoss = 'weightLoss',
  PrematureEjaculation = 'prematureEjaculation',
}

export enum FollowUpType {
  Initial = 'initial',
  General = 'general',
  Calibration = 'calibration',
}

export interface TreatmentPlanItem {
  variantSku: string;
  comment: string | null;
}

export interface TreatmentConfigurationItemModifierValue {
  modifierId: number;
  modifierValue: any;
}

export interface TreatmentConfigurationItem {
  name: string;
  productId: number;
  variantId: number;
  sku: string;
  quantity: number;
  modifierValues: TreatmentConfigurationItemModifierValue[];
  price: number;
  isTaxable: boolean;
  isDigital: boolean;
}

export interface TreatmentConfiguration extends BaseEntity {
  purchaseOption: PurchaseOption;
  productItems: TreatmentConfigurationItem[];
  serviceFeeItem: TreatmentConfigurationItem;
}

export interface CaseReportAttributes {
  initialWeight?: number;
  goalWeight?: number;
  bodyMassIndex?: number;
  ozempicAllowed?: boolean;
  weightLossMotivation?: string[];
}

export interface PatientFollowUp extends BaseEntity {
  scheduledDate: string;
  type: FollowUpType;
  activatedAt: string;
  openedAt: string | null;
  hasData: boolean;
  reviewed: boolean;
  repliedAt: string | null;
}

export interface PatientResolutionData {
  resolution: Resolution;
  resolvedBy: UserInfo;
  date: string;
  message: string | null;
}

export interface PatientCompletionData {
  completedBy: UserInfo;
  date: string;
  message: string;
}

export interface PatientOrderInfo {
  orderId: number;
  createdAt: string;
  resolutionData: PatientResolutionData | null;
  treatmentConfigurationId: string;
  pharmacyContactRequested: boolean | null;
}

export interface PatientCaseReportMessagesSummary {
  latestMessage: PatientMessage | null;
  unreadMessagesCount: number;
}

export enum MeasurementType {
  Weight = 'weight',
}

export enum MeasurementSource {
  Manual = 'manual',
  CaseReport = 'caseReport',
  FollowUp = 'followUp',
}

export interface Measurement extends BaseEntity {
  date: string;
  value: number;
  type: MeasurementType;
  source: MeasurementSource;
}

export interface PatientCaseReport extends BaseEntity {
  diseaseCategory: DiseaseCategory;
  assignee: UserInfo | null;
  resolutionData: PatientResolutionData | null;
  externalId: string;
  treatmentPlan: TreatmentPlanItem[];
  followUps: PatientFollowUp[];
  imageUploadFinished: boolean;
  submitted: boolean;
  finalized: boolean;
  mediaItems: MediaItem[];
  leadContactDetails: LeadContactDetails | null;
  treatmentConfigurations: TreatmentConfiguration[];
  currentTreatmentConfiguration: TreatmentConfiguration | null;
  isLegacyOnboarding: boolean;
  attributes: CaseReportAttributes;
  orders: PatientOrderInfo[];
  linked: boolean;
  completionData: PatientCompletionData | null;
  measurements: Measurement[];
}
