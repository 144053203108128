export enum PharmacyCode {
  Apovital = 'apovital',
  Svea = 'svea',
}

export interface PharmacyInfo {
  code: PharmacyCode;
  name: string;
  email: string;
  phoneNumber: string;
}
